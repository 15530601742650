






































import { Component, Vue } from "vue-property-decorator";
import AppOrderList from "@/components/AppOrderList.vue";
import AppSwitch from "@/components/core/AppSwitch.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import { findCatalog } from "@/utils/api";

@Component({
  components: {
    AppOrderList,
    AppSwitch,
    AppWidget,
    TheHeader,
    TheMain,
  },
})
export default class AppOrderHistory extends Vue {
  catalog: pro.Catalog | null = null;
  hideCompletedOrders = true;

  get catalogId(): pro.Id {
    return Number(this.$route.params.catalogId);
  }

  async mounted() {
    await this.loadCatalog();
  }

  async loadCatalog(): Promise<void> {
    this.catalog = await findCatalog({ id: this.catalogId });
  }
}
